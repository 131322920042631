// pages/dashboard/DashBoardPage.tsx

import { useUser } from '../../contexts/UserContext';
import CoursesSection from '../../components/dashboard/CoursesSection';

const DashboardPage = () => {
  const { profile, updateProfile } = useUser();

  return (
    <div className="min-h-screen">
      {/* Main content */}
      <div className="sm:pl-64">
        <div className="space-y-6 px-4 sm:px-6 lg:px-8 py-6">
          <div className="rounded-xl bg-slate-900/50 backdrop-blur-sm border border-slate-800 p-4 sm:p-6">
            <h1 className="text-xl sm:text-2xl font-bold text-white">Dashboard</h1>
            <p className="text-slate-400 mt-1 text-sm sm:text-base">Welcome to your dashboard</p>
          </div>

          <div className="grid gap-3 sm:gap-4 md:gap-5 grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            <h1 className="col-span-full text-lg sm:text-xl font-medium text-white">
              Welcome, {profile?.username}
            </h1>
            {/* Rest of your component */}
          </div>

          <CoursesSection />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
