import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTableCells,
  faBasketball,
  faHockeyPuck,
  faMoneyBillTrendUp,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

interface MenuItem {
  icon: any;
  label: string;
  path?: string;
  submenu?: Array<{
    name: string;
    path: string;
  }>;
  allowedPlans?: string[];
}

interface SideBarProps {
  onExpand?: (expanded: boolean) => void;
}

const SideBar = ({ onExpand }: SideBarProps) => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { profile } = useUser();

  const toggleSidebar = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    onExpand?.(newState);
  };

  const menuItems: MenuItem[] = [
    { 
      icon: faTableCells, 
      label: 'Dashboard',
      path: '/dashboard',
      allowedPlans: ['Royal']
    },
    { 
      icon: faMoneyBillTrendUp,
      label: 'Bet Tracker',
      path: '/dashboard/bet-tracker',
      allowedPlans: ['Royal', 'Apprentice', 'Commoner']
    },
    { 
      icon: faBasketball, 
      label: 'Basketball', 
      submenu: [
        { name: 'NBA', path: '/dashboard/nba' }
      ],
      allowedPlans: ['Royal', 'Apprentice']
    },
    { 
      icon: faHockeyPuck, 
      label: 'Hockey', 
      submenu: [
        { name: 'NHL', path: '/dashboard/nhl' }
      ],
      allowedPlans: ['Royal', 'Apprentice']
    },
    { 
      icon: faUser, 
      label: 'Profile',
      path: '/dashboard/profile',
      allowedPlans: ['Royal', 'Apprentice', 'Commoner']
    }
  ];

  const filteredMenuItems = menuItems.filter(item => {
    if (!item.allowedPlans || !profile) return false;
    return item.allowedPlans.includes(profile.plan);
  });

  const handleItemClick = (item: MenuItem) => {
    if (item.submenu) {
      // Always expand the sidebar and set the active menu
      setIsExpanded(true);
      onExpand?.(true);
      setActiveMenu(activeMenu === item.label && isExpanded ? null : item.label);
    } else if (item.path) {
      navigate(item.path);
      setIsExpanded(false);
      onExpand?.(false);
    }
  };

  const handleSubItemClick = (path: string) => {
    navigate(path);
    setIsExpanded(false);
    onExpand?.(false);
  };

  return (
    <aside 
      className={`
        fixed top-0 left-0 h-screen
        ${isExpanded ? 'w-64' : 'w-20'}
        bg-[rgba(255,255,255,0.03)] 
        backdrop-blur-sm 
        border-r 
        border-[rgba(255,255,255,0.05)] 
        transition-all 
        duration-300 
        flex 
        flex-col 
        z-50
        cursor-pointer
      `}
    >
      {/* Header - Clicking this toggles the sidebar */}
      <div 
        onClick={toggleSidebar}
        className="h-16 flex items-center border-b border-[rgba(255,255,255,0.05)] px-5 select-none"
      >
        <div className="flex items-center gap-3">
          <div className="w-10 flex justify-center">
            <img 
              src="https://i.imgur.com/83gZyXE.png" 
              alt="Overdog" 
              className="w-8 h-8" 
            />
          </div>
          <span className={`
            font-semibold text-xl 
            bg-gradient-to-r from-[#FFD426] to-[#00F6FF] 
            bg-clip-text text-transparent 
            whitespace-nowrap
            ${isExpanded ? 'block' : 'hidden'}
          `}>
            OVERDOG
          </span>
        </div>
      </div>

      {/* Navigation */}
      <nav className="flex-1 py-4 overflow-y-auto select-none">
        {filteredMenuItems.map((item, index) => (
          <div key={index}>
            <button
              onClick={() => handleItemClick(item)}
              className={`
                w-full flex items-center text-[#8F9BB3] 
                hover:bg-[#13131A] transition-all duration-200
                ${activeMenu === item.label ? 'bg-[#13131A] text-[#00F6FF]' : ''}
                hover:text-white
                py-3 px-5
              `}
            >
              <div className="w-10 flex justify-center pl-1.5">
                <FontAwesomeIcon 
                  icon={item.icon} 
                  className="w-6 h-6 transition-all duration-200" 
                />
              </div>
              <span className={`
                ml-3 whitespace-nowrap
                ${isExpanded ? 'block' : 'hidden'}
              `}>
                {item.label}
              </span>
            </button>
            
            {item.submenu && activeMenu === item.label && isExpanded && (
              <div className="bg-[#13131A]/50 backdrop-blur-sm">
                {item.submenu.map((subItem, subIndex) => (
                  <button
                    key={subIndex}
                    onClick={() => handleSubItemClick(subItem.path)}
                    className="
                      w-full py-2.5 px-[4.5rem] 
                      text-left text-[#8F9BB3] 
                      hover:text-[#00F6FF] 
                      hover:bg-[rgba(255,255,255,0.03)] 
                      transition-all duration-200
                    "
                  >
                    {subItem.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default SideBar;