import React from 'react';
import { ArrowUp } from 'lucide-react';

const ScrollToTop = () => {
   ('ScrollToTop component rendered');
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button 
      onClick={scrollToTop}
      className="p-6 rounded-full transition-all duration-300 hover:scale-105 flex items-center justify-center"
      style={{
        background: 'linear-gradient(135deg, rgba(13,14,20,1) 0%, rgba(10,11,16,1) 100%)',
        border: '3px solid rgba(42,45,54,0.5)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 8px 32px rgba(0,0,0,0.3)',
        width: '64px',
        height: '64px',
        position: 'fixed',
        bottom: '32px',
        right: '32px',
        zIndex: 99999
      }}
    >
      <ArrowUp className="w-8 h-8 text-white drop-shadow-[0_0_4px_rgba(0,0,0,0.8)]" />
    </button>
  );
};

export default ScrollToTop;
