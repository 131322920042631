import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Bookmaker } from '../../services/bookmakerService';
import { AutobetSettings } from '../../types/betting';

interface AutobetSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  bookmaker: Bookmaker;
  existingSettings?: AutobetSettings;
  onSave: (settings: AutobetSettings) => void;
}

const defaultSettings: AutobetSettings = {
  minOdds: '',
  maxOdds: '',
  stakeAmount: ''
};

const AutobetSettingsModal = ({ 
  isOpen, 
  onClose, 
  bookmaker, 
  existingSettings,
  onSave 
}: AutobetSettingsModalProps) => {
  const [settings, setSettings] = useState<AutobetSettings>(defaultSettings);
  const [error, setError] = useState<string | null>(null);

  // Reset form when modal opens/closes and load existing settings if available
  useEffect(() => {
    if (isOpen) {
       ('Modal opened with existing settings:', existingSettings);
      setSettings(existingSettings || defaultSettings);
      setError(null);
    }
  }, [isOpen, existingSettings]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
     ('Input changed:', name, value);
    // Only allow numbers and decimal points
    if (/^\d*\.?\d*$/.test(value)) {
      setSettings(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
     ('Attempting to save settings:', settings);
    setError(null);

    // Validate fields
    if (!settings.minOdds || !settings.maxOdds || !settings.stakeAmount) {
      setError('Please fill in all fields');
      return;
    }

    // Validate min odds is less than max odds
    if (parseFloat(settings.minOdds) >= parseFloat(settings.maxOdds)) {
      setError('Minimum odds must be less than maximum odds');
      return;
    }

    // Validate stake amount is greater than 0
    if (parseFloat(settings.stakeAmount) <= 0) {
      setError('Stake amount must be greater than 0');
      return;
    }

     ('Settings validated, saving...');
    onSave(settings);
    onClose();
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
  };

  if (!isOpen) return null;

  const modalContent = (
    <div 
      className="fixed inset-0 flex items-center justify-center z-[9999]"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
      onClick={handleClose}
    >
      <div 
        className="bg-[#1A1A23] rounded-xl p-6 w-full max-w-xl mx-4 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-white text-xl font-semibold">
            {existingSettings ? 'Edit Autobet Settings' : 'Enable Autobet'} - {bookmaker.name}
          </h2>
          <button 
            onClick={handleClose}
            className="text-gray-400 hover:text-white"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-5">
          <div className="text-white">
            <label htmlFor="minOdds" className="block mb-2 text-gray-400">Minimum Odds</label>
            <input
              id="minOdds"
              name="minOdds"
              type="text"
              value={settings.minOdds}
              onChange={handleInputChange}
              placeholder="Enter minimum odds"
              className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB]"
            />
          </div>

          <div className="text-white">
            <label htmlFor="maxOdds" className="block mb-2 text-gray-400">Maximum Odds</label>
            <input
              id="maxOdds"
              name="maxOdds"
              type="text"
              value={settings.maxOdds}
              onChange={handleInputChange}
              placeholder="Enter maximum odds"
              className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB]"
            />
          </div>

          <div className="text-white">
            <label htmlFor="stakeAmount" className="block mb-2 text-gray-400">Stake Amount ($)</label>
            <input
              id="stakeAmount"
              name="stakeAmount"
              type="text"
              value={settings.stakeAmount}
              onChange={handleInputChange}
              placeholder="Enter stake amount"
              className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB]"
            />
          </div>

          {error && (
            <div className="text-red-500 text-sm mt-2">
              {error}
            </div>
          )}

          <button
            className="w-full bg-[#4263EB] text-white py-3 px-4 rounded-lg font-medium hover:bg-[#3651C9] transition-colors disabled:opacity-50 disabled:cursor-not-allowed mt-6"
            onClick={handleSave}
          >
            {existingSettings ? 'Save Changes' : 'Enable Autobet'}
          </button>
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default AutobetSettingsModal;
