import { Routes, Route } from 'react-router-dom';
import { ProtectedRoutes } from '../../routes/protected/ProtectedRoutes';
import { RoleProtectedRoute } from '../../routes/protected/RoleProtectedRoute';
import { useState } from 'react';

// Page Components
import DashboardPage from './DashBoardPage';
import NBAPage from './NBAPage';
import NBAPlayerPage from './NBAPlayerPage';
import ParlayGenerator from './ParlayPage';
import BetTrackerPage from './BetTrackerPage';
import NHLPage from './NHLPage';
import PropExplorerNBAPage from './PropExplorerNBAPage';
import ProfilePage from './ProfilePage';
import BookmakersPage from './BookmakersPage';
import DiscordCallback from '../../components/dashboard/DiscordCallback';

// Components
import SideBar from '../../components/dashboard/SideBar';
import NavBar from '../../components/dashboard/NavBar';
import PinnedGamesBar from '../../components/dashboard/PinnedGamesBar';

// Context
import { PinnedGamesProvider } from '../../contexts/PinnedGamesContext';

// Styles
import '../../styles/dashboard.css';

export const DashboardLayout = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <PinnedGamesProvider>
      <div className="font-[Montserrat] min-h-screen bg-[#0A0A0F] text-white relative">
        {/* Background Gradients */}
        <div className="fixed inset-0 pointer-events-none overflow-hidden">
          <div className="absolute right-0 top-0 w-1/3 h-1/3 bg-[rgba(0,246,255,0.15)] opacity-25 blur-3xl" />
          <div className="absolute left-0 bottom-0 w-1/3 h-1/3 bg-[rgba(0,246,255,0.15)] opacity-25 blur-3xl" />
        </div>

        {/* Main Layout */}
        <div className="relative flex min-h-screen">
          {/* Sidebar */}
          <SideBar onExpand={setIsExpanded} />

          {/* Main Content Area */}
          <div className={`
            relative flex-1 flex flex-col min-w-0 
            transition-all duration-300 
            ${isExpanded ? 'pl-64' : 'pl-20'}
          `}>
            {/* Navbar */}
            <NavBar 
              className={`
                transition-all duration-300
                ${isExpanded ? 'left-64' : 'left-20'}
              `}
            />

            {/* Page Content */}
            <main className={`
              flex-1 
              px-4 md:px-6 lg:px-8 
              py-8 
              mt-16
              transition-all 
              duration-300
              max-w-[1600px] 
              mx-auto 
              w-full
              relative
              z-10
            `}>
              <Routes>
                <Route element={<ProtectedRoutes />}>
                  {/* Dashboard - Royal Only */}
                  <Route
                    index
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal']}>
                        <DashboardPage />
                      </RoleProtectedRoute>
                    }
                  />

                  {/* NBA Routes - Royal & Apprentice */}
                  <Route
                    path="prop-explorer/nba"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice']}>
                        <PropExplorerNBAPage />
                      </RoleProtectedRoute>
                    }
                  />
                  <Route
                    path="nba"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice']}>
                        <NBAPage />
                      </RoleProtectedRoute>
                    }
                  />
                  <Route
                    path="nba/player"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice']}>
                        <NBAPlayerPage />
                      </RoleProtectedRoute>
                    }
                  />

                  {/* NHL Routes - Royal & Apprentice */}
                  <Route
                    path="nhl"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice']}>
                        <NHLPage />
                      </RoleProtectedRoute>
                    }
                  />

                  {/* Parlay Generator - Royal Only */}
                  <Route
                    path="parlay"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal']}>
                        <ParlayGenerator />
                      </RoleProtectedRoute>
                    }
                  />

                  {/* Bet Tracker - All Plans */}
                  <Route
                    path="bet-tracker"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice', 'Commoner']}>
                        <BetTrackerPage />
                      </RoleProtectedRoute>
                    }
                  />

                  {/* Profile Routes - All Plans */}
                  <Route
                    path="profile"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal', 'Apprentice', 'Commoner']}>
                        <ProfilePage />
                      </RoleProtectedRoute>
                    }
                  />
                  <Route path="profile/discord/callback" element={<DiscordCallback />} />

                  {/* Bookmakers - Royal Only */}
                  <Route
                    path="bookmakers"
                    element={
                      <RoleProtectedRoute allowedPlans={['Royal']}>
                        <BookmakersPage />
                      </RoleProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </main>

            {/* Footer */}
            <footer className="mt-auto py-4 px-6 text-center text-[#8F9BB3] text-sm">
              © {new Date().getFullYear()} Overdog. All rights reserved.
            </footer>
          </div>
        </div>

        {/* Pinned Games Bar */}
        <div className="fixed z-50 bottom-0 right-0 left-0">
          <div className={`
            transition-all duration-300 
            ml-20 ${isExpanded ? 'ml-64' : 'ml-20'}
          `}>
            <PinnedGamesBar />
          </div>
        </div>
      </div>
    </PinnedGamesProvider>
  );
};

export default DashboardLayout;