import React from 'react';
import Navbar from './Navbar';
import ScrollToTop from './ScrollToTop';
import HeroContent from './HeroContent';
import PredictionCards from './PredictionCards';
import PlatformSection from './PlatformSection';
import Features from './Features';
import BettingOverview from './BettingOverview';
import PricingSection from './PricingSection';
import TestimonialsSection from './TestimonialsSection';
import CTASection from './CTASection';
import FAQSection from './FAQSection';
import Footer from './Footer';
import GlobalReferralBox from './GlobalReferralBox';

const Layout = () => {
  return (
    <div className="min-h-screen relative overflow-hidden text-white bg-[#111111]">
      {/* Global background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#111111] to-black opacity-50 pointer-events-none" />
      
      {/* Content */}
      <Navbar />
      
      <main className="min-h-screen flex flex-col pt-14 xs:pt-16 sm:pt-20 md:pt-24 overflow-x-hidden">
        {/* Hero Section */}
        <section className="flex-1 relative z-10 mb-8 sm:mb-12 md:mb-16 lg:mb-20 xl:mb-24">
          <div className="min-h-[calc(100vh-64px)] xs:min-h-[calc(100vh-72px)] sm:min-h-[calc(100vh-80px)] md:min-h-[calc(100vh-96px)] flex flex-col md:flex-row items-center justify-center gap-4 xs:gap-5 sm:gap-6 md:gap-8 lg:gap-12 xl:gap-16 w-full max-w-screen-2xl mx-auto px-3 xs:px-4 sm:px-5 md:px-6 lg:px-8 overflow-hidden">
            <HeroContent />
            <PredictionCards />
          </div>
        </section>

        {/* Content Sections */}
        <div className="w-full max-w-screen-2xl mx-auto px-3 xs:px-4 sm:px-5 md:px-6 lg:px-8 relative z-10 space-y-6 xs:space-y-8 sm:space-y-10 md:space-y-14 lg:space-y-20 xl:space-y-24 overflow-x-hidden">
          <PlatformSection />
          <Features />
          <BettingOverview />
        </div>
      </main>

      {/* Floating action buttons 
      <GlobalReferralBox />*/}
      <div className="fixed bottom-3 right-3 xs:bottom-4 xs:right-4 sm:bottom-6 sm:right-6 md:bottom-8 md:right-8 z-[9999]">
        <ScrollToTop />
      </div>

      {/* Bottom Sections */}
      <div className="relative z-10 mt-8 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-24 overflow-x-hidden">
        <PricingSection />
        <TestimonialsSection />
        <CTASection />
        <FAQSection />
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
