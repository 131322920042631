import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../contexts/NotificationContext';
import { Notification } from '../../services/notificationService';
import { format, formatDistanceToNow } from 'date-fns';

interface NotificationBellProps {
  className?: string;
}

const NotificationBell: React.FC<NotificationBellProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { notifications, unreadCount, markAsRead, markAllAsRead } = useNotifications();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.read) {
      await markAsRead(notification.id);
    }

    if (notification.metadata?.url) {
      window.location.href = notification.metadata.url;
    }
  };

  const getNotificationStyle = (type: string, status?: string) => {
    if (type === 'bet_result') {
      if (status === 'won') {
        return 'bg-green-900/20 text-green-400 border border-green-500/20';
      } else if (status === 'loss') {
        return 'bg-red-900/20 text-red-400 border border-red-500/20';
      }
      // For pending bets
      return 'bg-cyan-900/20 text-cyan-400 border border-cyan-500/20';
    }
    return 'bg-cyan-900/20 text-cyan-400 border border-cyan-500/20';
  };

  const formatDate = (dateString?: string) => {
    if (!dateString) return '';

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '';
      
      // For recent dates (less than 24 hours), show relative time
      if (Date.now() - date.getTime() < 24 * 60 * 60 * 1000) {
        return formatDistanceToNow(date, { addSuffix: true });
      }
      
      // For older dates, show the full date
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error, 'Date value:', dateString);
      return '';
    }
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-400 hover:text-white focus:outline-none"
        aria-label="Notifications"
      >
        <FontAwesomeIcon icon={faBell} className="w-6 h-6" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="fixed inset-x-0 top-14 mx-auto sm:absolute sm:inset-auto sm:right-0 sm:top-auto sm:mt-2 w-full sm:w-96 max-h-[80vh] overflow-y-auto bg-[#1A1C2E] rounded-none sm:rounded-lg shadow-lg z-50">
          <div className="sticky top-0 p-4 border-b border-gray-700 bg-[#1A1C2E]">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold text-white">Notifications</h3>
              {unreadCount > 0 && (
                <button
                  onClick={() => markAllAsRead()}
                  className="text-sm text-cyan-400 hover:text-cyan-300"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>

          <div className="divide-y divide-gray-700">
            {notifications.length === 0 ? (
              <div className="p-4 text-center text-gray-400">
                No notifications
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  className={`
                    p-4 cursor-pointer transition-colors duration-150
                    ${notification.read ? 'opacity-60' : 'opacity-100'}
                    ${getNotificationStyle(notification.type, notification.metadata?.status)}
                    hover:bg-opacity-75
                  `}
                >
                  <div className="flex justify-between items-start gap-2">
                    <p className="text-sm flex-grow break-words">{notification.message}</p>
                    {!notification.read && (
                      <span className="h-2 w-2 bg-blue-500 rounded-full flex-shrink-0 mt-1"></span>
                    )}
                  </div>
                  <div className="mt-1 text-xs text-gray-400">
                    {formatDate(notification.createdAt)}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBell;