import React from 'react';
import CourseCard from './CourseCard';
import { liveBettingLessons } from './LiveBettingContent';
import { bankrollLessons } from './BankrollManagementContent';

const CoursesSection = () => {
  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold text-white mb-4">Courses</h2>
      <div className="grid gap-3 sm:gap-4 md:gap-5 grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <div>
            <CourseCard
              title="Basics of Sports Betting"
              description="Learn the fundamentals of sports betting and start earning!"
              imageUrl="assets/img/courses/basics.jpg"
              courseType="basic"
            />
          </div>
        <div>
            <CourseCard
              title="Advanced Strategies"
              description="Master advanced betting strategies and maximize your profits"
              imageUrl="assets/img/courses/advanced.jpg"
              courseType="advanced"
            />
          </div>
        <div>
            <CourseCard
              title="Live Betting"
              description="Master real-time betting strategies and capitalize on in-game opportunities"
              imageUrl="assets/img/courses/live.jpg"
              courseType="advanced"
              lessons={liveBettingLessons}
            />
          </div>
        <div>
            <CourseCard
              title="Bankroll Management"
              description="Proper money management techniques for long-term success"
              imageUrl="assets/img/courses/bankroll.jpg"
              courseType="basic"
              lessons={bankrollLessons}
            />
          </div>
      </div>
    </div>
  );
};

export default CoursesSection;
