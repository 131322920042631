export const DEFAULT_ODDS = -110;
export const HIGH_CONFIDENCE_THRESHOLD = 60;

export const NHL_TEAM_ABBREVIATIONS: { [key: string]: string } = {
    // Full team names
    "Anaheim Ducks": "ANA",
    "Arizona Coyotes": "ARI",
    "Boston Bruins": "BOS",
    "Buffalo Sabres": "BUF",
    "Calgary Flames": "CGY",
    "Carolina Hurricanes": "CAR",
    "Chicago Blackhawks": "CHI",
    "Colorado Avalanche": "COL",
    "Columbus Blue Jackets": "CBJ",
    "Dallas Stars": "DAL",
    "Detroit Red Wings": "DET",
    "Edmonton Oilers": "EDM",
    "Florida Panthers": "FLA",
    "Los Angeles Kings": "LAK",
    "Minnesota Wild": "MIN",
    "Montreal Canadiens": "MTL",
    "Nashville Predators": "NSH",
    "New Jersey Devils": "NJD",
    "New York Islanders": "NYI",
    "New York Rangers": "NYR",
    "Ottawa Senators": "OTT",
    "Philadelphia Flyers": "PHI",
    "Pittsburgh Penguins": "PIT",
    "San Jose Sharks": "SJS",
    "Seattle Kraken": "SEA",
    "St. Louis Blues": "STL",
    "Tampa Bay Lightning": "TBL",
    "Toronto Maple Leafs": "TOR",
    "Vancouver Canucks": "VAN",
    "Vegas Golden Knights": "VGK",
    "Washington Capitals": "WSH",
    "Winnipeg Jets": "WPG",
    "Utah Hockey Club": "UTA",
    
    // City/Location only
    "Anaheim": "ANA",
    "Arizona": "ARI",
    "Boston": "BOS",
    "Buffalo": "BUF",
    "Calgary": "CGY",
    "Carolina": "CAR",
    "Chicago": "CHI",
    "Colorado": "COL",
    "Columbus": "CBJ",
    "Dallas": "DAL",
    "Detroit": "DET",
    "Edmonton": "EDM",
    "Florida": "FLA",
    "Los Angeles": "LAK",
    "Minnesota": "MIN",
    "Montreal": "MTL",
    "Nashville": "NSH",
    "New Jersey": "NJD",
    "New York": "NYR", // Default to Rangers for ambiguous "New York"
    "Ottawa": "OTT",
    "Philadelphia": "PHI",
    "Pittsburgh": "PIT",
    "San Jose": "SJS",
    "Seattle": "SEA",
    "St. Louis": "STL",
    "Tampa Bay": "TBL",
    "Toronto": "TOR",
    "Vancouver": "VAN",
    "Vegas": "VGK",
    "Washington": "WSH",
    "Winnipeg": "WPG",
    "Utah": "UTA",
    
    // Nicknames only
    "Ducks": "ANA",
    "Coyotes": "ARI",
    "Bruins": "BOS",
    "Sabres": "BUF",
    "Flames": "CGY",
    "Hurricanes": "CAR",
    "Blackhawks": "CHI",
    "Avalanche": "COL",
    "Blue Jackets": "CBJ",
    "Stars": "DAL",
    "Red Wings": "DET",
    "Oilers": "EDM",
    "Panthers": "FLA",
    "Kings": "LAK",
    "Wild": "MIN",
    "Canadiens": "MTL",
    "Habs": "MTL",
    "Predators": "NSH",
    "Devils": "NJD",
    "Islanders": "NYI",
    "Rangers": "NYR",
    "Senators": "OTT",
    "Flyers": "PHI",
    "Penguins": "PIT",
    "Sharks": "SJS",
    "Kraken": "SEA",
    "Blues": "STL",
    "Lightning": "TBL",
    "Maple Leafs": "TOR",
    "Leafs": "TOR",
    "Canucks": "VAN",
    "Golden Knights": "VGK",
    "Knights": "VGK",
    "Capitals": "WSH",
    "Caps": "WSH",
    "Jets": "WPG"
};

export const getWinningTeam = (game: any): string => {
    const homeProb = parseFloat(game["Pre-Game Home Win Probability"]);
    const awayProb = parseFloat(game["Pre-Game Away Win Probability"]);
    // Simply return the team with the higher probability
    return homeProb > awayProb ? game["Home Team"] : game["Away Team"];
};

export const getTeamAbbreviation = (teamName: string): string => {
    // Remove any numbers or dots from the start of the team name
    const cleanTeamName = teamName.replace(/^\d+\.\s*/, '').trim();
    return NHL_TEAM_ABBREVIATIONS[cleanTeamName] || cleanTeamName.substring(0, 3).toUpperCase();
};

export const getNHLLogoPath = (teamName: string): string => {
    const abbr = getTeamAbbreviation(teamName);
    // List of teams that have local logos
    const availableLogos = [
        'ANA', 'ARI', 'BOS', 'BUF', 'CAR', 'CBJ', 'CGY', 'CHI', 'COL',
        'DAL', 'DET', 'EDM', 'FLA', 'LAK', 'MIN', 'MTL', 'NSH', 'OTT',
        'PHI', 'PIT', 'SEA', 'SJS', 'STL', 'TBL', 'TOR', 'UTA', 'VAN',
        'VGK', 'WPG', 'WSH'
    ];
    
    // Only return a local logo path if we have the logo file
    return availableLogos.includes(abbr) ? `/assets/img/nhl-logos/${abbr}.png` : '';
};

export const getGamePrediction = (game: any) => {
    const homeProb = parseFloat(game["Pre-Game Home Win Probability"]);
    const awayProb = parseFloat(game["Pre-Game Away Win Probability"]);
    
    return {
        winningTeam: getWinningTeam(game),
        confidence: Math.max(homeProb, awayProb),
        defaultOdds: DEFAULT_ODDS
    };
};
