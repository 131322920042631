import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 px-4 xs:px-5 sm:px-6 py-3 sm:py-4 md:py-5 z-50 bg-[rgba(0,0,0,0.3)] backdrop-blur-sm md:backdrop-blur">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-2 xs:space-x-3 sm:space-x-4">
          <img 
            src="/logo.png" 
            alt="Overdog Logo" 
            className="w-32 xs:w-36 sm:w-40 md:w-44 lg:w-48 py-1"
          />
          <Link 
            to="/auth"
            style={{
              background: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(8px)',
              WebkitBackdropFilter: 'blur(8px)',
            }}
            className="xs:inline-block px-4 sm:px-5 md:px-6 py-1.5 sm:py-2 md:py-2.5 rounded-full font-medium text-sm sm:text-base md:text-lg text-white bg-black border border-[rgba(255,255,255,0.1)] hover:border-[rgba(255,255,255,0.2)] transition-all duration-200 hover:translate-y-[-2px] hover:shadow-lg active:scale-[0.98] active:bg-black/50"
          >
            Become an Overdog
          </Link>
        </div>
        
        <div className="flex items-center">
          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 text-gray-400 hover:text-white focus:outline-none"
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M4 6h16M4 12h16M4 18h16" 
              />
            </svg>
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8 lg:space-x-12 mr-8 lg:mr-10">
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-all duration-200 py-2 px-3 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
              Affiliates
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-all duration-200 py-2 px-3 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
              Roadmap
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-all duration-200 py-2 px-3 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
              Blog
            </a>
            <a href="#" className="text-white/80 text-base font-light hover:text-white transition-all duration-200 py-2 px-3 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
              Pricing
            </a>
          </div>

          {/* Mobile Menu */}
          <div className={`md:hidden absolute top-full right-0 w-64 bg-black/95 backdrop-blur-lg z-50 transform transition-all duration-300 ease-in-out ${
            isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-10px]'
          }`}>
            <div className="flex flex-col space-y-3 p-4">
              <div className="flex items-center justify-between border-b border-white/10 pb-3 mb-3">
                <h3 className="text-white text-lg font-medium">Menu</h3>
                <button 
                  onClick={() => setIsOpen(false)}
                  className="p-1 text-gray-400 hover:text-white active:scale-95 transition-transform"
                >
                  <svg 
                    className="w-6 h-6" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M6 18L18 6M6 6l12 12" 
                    />
                  </svg>
                </button>
              </div>
              
              <a href="#" className="text-white/90 text-lg font-medium hover:text-white transition-all duration-200 py-3 px-4 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
                Affiliates
              </a>
              <a href="#" className="text-white/90 text-lg font-medium hover:text-white transition-all duration-200 py-3 px-4 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
                Roadmap
              </a>
              <a href="#" className="text-white/90 text-lg font-medium hover:text-white transition-all duration-200 py-3 px-4 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
                Blog
              </a>
              <a href="#" className="text-white/90 text-lg font-medium hover:text-white transition-all duration-200 py-3 px-4 rounded-lg hover:bg-white/5 active:bg-white/10 active:scale-[0.98]">
                Pricing
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
