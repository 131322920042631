import { useState, useEffect } from 'react';
import { NHLPredictionsResponse, NHLPrediction } from '../../../types/nhlPredictions';
import { fetchNHLPredictions } from '../../../services/nhlPredictionsService';
import { getTeamAbbreviation, getNHLLogoPath } from '../../../utils/nhlUtils';
import { useAuth } from '../../../hooks/useAuth';
import { supabase } from '../../../lib/supabase/client';
import BettingModal from './BettingModal';
import { useOddsLocation } from '@/contexts/OddsLocationContext';

const NHLSingles = () => {
  const [predictions, setPredictions] = useState<NHLPredictionsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<NHLPrediction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();
  const { oddsFormat } = useOddsLocation();

  useEffect(() => {
    const loadPredictions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNHLPredictions(session.access_token);
          setPredictions(data);
        } else {
          setError('Authentication required');
        }
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load predictions';
        console.error('Error loading predictions:', err);
        setError(errorMessage);
      }
    };

    if (user) {
      loadPredictions();
    }
  }, [user]);

  const handleAddBet = (game: NHLPrediction, e: React.MouseEvent) => {
    e.stopPropagation();
    if (game['Predicted Winner'] !== "AI Unsure") {
      setSelectedGame(game);
      setIsModalOpen(true);
    }
  };

  if (error) return <div className="text-red-500 p-4">{error}</div>;
  if (!predictions) return <div className="text-white p-4">Loading predictions...</div>;

  const predictionsList = Object.values(predictions);
  if (predictionsList.length === 0) {
    return <div className="text-white p-4">No predictions available for today.</div>;
  }

  return (
    <>
      <div className="space-y-4 w-full px-2 md:px-4">
        {predictionsList.map((game, index) => (
          <div
            key={index}
            className="bg-[#13131A] rounded-xl overflow-hidden transition-colors w-full border border-[#2E2E3A] cursor-pointer"
          >
            <div className="grid grid-cols-1 md:grid-cols-[auto_1fr_auto] gap-4 md:gap-6 p-3 md:p-4 w-full items-center bg-[#1A1A24] rounded-lg">
              {/* Logos Section */}
              <div className="flex items-center gap-4 justify-center md:justify-start md:ml-8">
                <img
                  src={getNHLLogoPath(game['Away Team'] || '')}
                  alt={game['Away Team'] || 'Away Team'}
                  className="w-10 h-10 md:w-12 md:h-12"
                />
                <div className="text-[#8F9BB3] text-sm">vs</div>
                <img
                  src={getNHLLogoPath(game['Home Team'] || '')}
                  alt={game['Home Team'] || 'Home Team'}
                  className="w-10 h-10 md:w-12 md:h-12"
                />
              </div>

              {/* Stats Section */}
              <div className="flex gap-8 items-center justify-center md:mx-auto">
                <div className="flex flex-col items-center">
                  <img
                    src={getNHLLogoPath(game['Away Team'] || '')}
                    alt={game['Away Team'] || 'Away Team'}
                    className="w-6 h-6 md:w-8 md:h-8 mb-1"
                  />
                  <div className="text-[#8F9BB3] text-xs mb-1">Away</div>
                  <div className="text-white text-xs md:text-sm">
                    {game['Away Team'] || 'TBD'}
                  </div>
                </div>
                
                <div className="flex flex-col items-center">
                  <img
                    src={getNHLLogoPath(game['Home Team'] || '')}
                    alt={game['Home Team'] || 'Home Team'}
                    className="w-6 h-6 md:w-8 md:h-8 mb-1"
                  />
                  <div className="text-[#8F9BB3] text-xs mb-1">Home</div>
                  <div className="text-white text-xs md:text-sm">
                    {game['Home Team'] || 'TBD'}
                  </div>
                </div>
              </div>

              {/* Prediction & Add Bet Section */}
              <div className="flex flex-col items-center md:items-end gap-2">
                <div className="text-center md:text-right">
                  <div className="text-[#8F9BB3] text-xs mb-1">Prediction</div>
                  <div className="text-white text-sm font-semibold">
                    {game['Predicted Winner']}
                  </div>
                  <div className="w-16 mx-auto md:ml-auto">
                    <div className={`text-sm ${
                      game['Predicted Winner'] === "AI Unsure"
                        ? 'text-[#8F9BB3]'
                        : 'text-[#00F6FF]'
                    }`}>
                      {game['Predicted Winner'] === "AI Unsure" ? 'N/A' : 'Win'}
                    </div>
                  </div>
                </div>
                <button
                  onClick={(e) => handleAddBet(game, e)}
                  className="bg-[#4263EB] text-white py-1 px-3 rounded-md text-sm font-medium hover:bg-[#3651C9] transition-colors disabled:opacity-50 disabled:cursor-not-allowed w-full md:w-auto"
                  disabled={game['Predicted Winner'] === "AI Unsure"}
                >
                  Add Bet
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedGame && (
        <BettingModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedGame(null);
          }}
          game={selectedGame}
          eventId={selectedGame.id}
          oddsFormat={oddsFormat}
        />
      )}
    </>
  );
};

export default NHLSingles;