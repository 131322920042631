// routes/protected/ProtectedRoutes.tsx
import { ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';

interface ProtectedRoutesProps {
  children?: ReactNode;
}

export function ProtectedRoutes({ children }: ProtectedRoutesProps) {
  const { user } = useAuthContext();
  const { profile, loading } = useUser();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (loading || !profile) {
    return <LoadingSpinner fullScreen />;
  }

  return children ? <>{children}</> : <Outlet />;
}
