import { useState, useMemo } from 'react';
import { Bet } from '../../types/betting';
import { useOddsLocation } from '@/contexts/OddsLocationContext';
import { convertOdds } from '@/utils/oddsConverter';
import { Menu } from 'lucide-react';

interface BetsOverviewProps {
  bets: Bet[];
  selectedBookmaker: string | null;
  onBookmakerSelect: (bookmaker: string | null) => void;
}

const BetsOverview = ({ bets, selectedBookmaker, onBookmakerSelect }: BetsOverviewProps) => {
  const [sportFilter, setSportFilter] = useState<string>('all');
  const [leagueFilter, setLeagueFilter] = useState<string>('all');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [bookmakerFilter, setBookmakerFilter] = useState<string>('all');
  const [expandedParlays, setExpandedParlays] = useState<Set<string>>(new Set());
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const { oddsFormat, formatCurrency } = useOddsLocation();

  // Existing helper functions
  const formatOdds = (odds: string) => {
    if (!odds) return '-';
    
    // Parse as float instead of int
    const oddsNumber = parseFloat(odds);
    if (isNaN(oddsNumber)) return odds;
    
    // If odds are in decimal format (>= 1.00), format directly
    if (oddsNumber >= 1.00) {
      console.log(`Formatting decimal odds: ${odds} -> ${oddsNumber.toFixed(2)}`);
      return oddsNumber.toFixed(2);
    }
    
    // If odds are in American format, convert to selected format
    const converted = convertOdds(oddsNumber, oddsFormat);
    console.log(`Formatting American odds: ${odds} -> ${converted}`);
    return converted;
  };

  const toggleParlay = (betId: string) => {
    setExpandedParlays(prev => {
      const newSet = new Set(prev);
      if (newSet.has(betId)) {
        newSet.delete(betId);
      } else {
        newSet.add(betId);
      }
      return newSet;
    });
  };

  const { filteredBets, sports, leagues, statuses, bookmakers } = useMemo(() => {
    const sportsSet = new Set<string>();
    const leaguesSet = new Set<string>();
    const statusesSet = new Set<string>();
    const bookmakersSet = new Set<string>();

    bets.forEach(bet => {
      if (bet.bookmaker?.name) bookmakersSet.add(bet.bookmaker.name);
      if (Array.isArray(bet.legs)) {
        bet.legs.forEach(leg => {
          if (leg.sport?.name) sportsSet.add(leg.sport.name);
          if (leg.league?.name) leaguesSet.add(leg.league.name);
          if (leg.bet_status?.name) statusesSet.add(leg.bet_status.name);
        });
      }
    });

    const filtered = bets.filter(bet => {
      const matchesSport = sportFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.sport?.name === sportFilter));
      const matchesLeague = leagueFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.league?.name === leagueFilter));
      const matchesStatus = statusFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.bet_status?.name === statusFilter));
      const matchesBookmaker = bookmakerFilter === 'all' || bet.bookmaker?.name === bookmakerFilter;
      
      return matchesSport && matchesLeague && matchesStatus && matchesBookmaker;
    });

    return {
      filteredBets: filtered,
      sports: Array.from(sportsSet).sort(),
      leagues: Array.from(leaguesSet).sort(),
      statuses: Array.from(statusesSet).sort(),
      bookmakers: Array.from(bookmakersSet).sort()
    };
  }, [bets, sportFilter, leagueFilter, statusFilter, bookmakerFilter]);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Won':
        return 'bg-green-500/20 text-green-500';
      case 'Pending':
        return 'bg-cyan-500/20 text-cyan-500';
      case 'Loss':
        return 'bg-red-500/20 text-red-500';
      case 'Void':
        return 'bg-yellow-500/20 text-yellow-500';
      case 'Cashout':
        return 'bg-purple-500/20 text-purple-500';
      default:
        return 'bg-cyan-500/20 text-cyan-500';
    }
  };

  const calculatePayout = (bet: Bet) => {
    if (!bet.bet_status || bet.bet_status.name === 'Pending') {
      return formatCurrency(0);
    }
  
    // For decimal odds, payout is simply stake * odds
    const oddsNumber = parseFloat(bet.odds);
    if (!isNaN(oddsNumber) && oddsNumber >= 1.00) {
      const totalPayout = bet.stake * oddsNumber;
      return formatCurrency(totalPayout);
    }
  
    return formatCurrency(0);
  };
  
  const calculatePL = (bet: Bet) => {
    if (!bet.bet_status || bet.bet_status.name === 'Pending') {
      return formatCurrency(0);
    }
  
    // For decimal odds
    const oddsNumber = parseFloat(bet.odds);
    if (!isNaN(oddsNumber) && oddsNumber >= 1.00) {
      if (bet.legs.length === 1) {
        const leg = bet.legs[0];
        if (leg.bet_status?.name === 'Won') {
          const totalPayout = bet.stake * oddsNumber;
          return formatCurrency(totalPayout - bet.stake); // P/L is payout minus stake
        } else if (leg.bet_status?.name === 'Loss') {
          return formatCurrency(-bet.stake);
        }
      } else {
        // Handle parlays
        const parlayStatus = getParlayStatus(bet);
        if (parlayStatus === 'Won') {
          const totalPayout = bet.stake * oddsNumber;
          return formatCurrency(totalPayout - bet.stake);
        } else if (parlayStatus === 'Loss') {
          return formatCurrency(-bet.stake);
        }
      }
    }
  
    return formatCurrency(0);
  };
  
  const getParlayStatus = (bet: Bet): string => {
    if (bet.legs.some(leg => leg.bet_status?.name === 'Loss')) {
      return 'Loss';
    }
    if (bet.legs.every(leg => leg.bet_status?.name === 'Won')) {
      return 'Won';
    }
    if (bet.legs.some(leg => leg.bet_status?.name === 'Pending')) {
      return 'Pending';
    }
    return bet.bet_status?.name || 'Pending';
  };

  const renderMobileFilters = () => (
    <div className={`${showMobileFilters ? 'flex' : 'hidden'} flex-col gap-2 p-4 bg-[#13131A] rounded-lg md:hidden`}>
      <select 
        className="w-full px-4 py-2 rounded-lg bg-[#1A1A23] text-[#8F9BB3] text-sm"
        value={bookmakerFilter}
        onChange={(e) => setBookmakerFilter(e.target.value)}
      >
        <option value="all">All Bookmakers</option>
        {bookmakers.map(bookmaker => (
          <option key={bookmaker} value={bookmaker}>{bookmaker}</option>
        ))}
      </select>
      <select 
        className="w-full px-4 py-2 rounded-lg bg-[#1A1A23] text-[#8F9BB3] text-sm"
        value={sportFilter}
        onChange={(e) => setSportFilter(e.target.value)}
      >
        <option value="all">All Sports</option>
        {sports.map(sport => (
          <option key={sport} value={sport}>{sport}</option>
        ))}
      </select>
      <select 
        className="w-full px-4 py-2 rounded-lg bg-[#1A1A23] text-[#8F9BB3] text-sm"
        value={leagueFilter}
        onChange={(e) => setLeagueFilter(e.target.value)}
      >
        <option value="all">All Leagues</option>
        {leagues.map(league => (
          <option key={league} value={league}>{league}</option>
        ))}
      </select>
      <select 
        className="w-full px-4 py-2 rounded-lg bg-[#1A1A23] text-[#8F9BB3] text-sm"
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value)}
      >
        <option value="all">All Statuses</option>
        {statuses.map(status => (
          <option key={status} value={status}>{status}</option>
        ))}
      </select>
    </div>
  );

  const renderMobileBetCard = (bet: Bet) => {
    const isParlay = bet.legs.length > 1;
    const isExpanded = expandedParlays.has(bet.id);
    const firstLeg = bet.legs[0];
    const status = isParlay ? getParlayStatus(bet) : firstLeg?.bet_status?.name;
    
    return (
      <div key={bet.id} className="bg-[#13131A] rounded-lg p-4 space-y-4">
        <div className="flex justify-between items-start">
          <div>
            <div className="text-white font-medium">
              {isParlay ? (
                <div className="flex items-center gap-2">
                  <span>Parlay ({bet.legs.length} Legs)</span>
                  <button
                    onClick={() => toggleParlay(bet.id)}
                    className="text-cyan-500"
                  >
                    {isExpanded ? '▼' : '▶'}
                  </button>
                </div>
              ) : (
                'Single'
              )}
            </div>
            <div className="text-[#8F9BB3] text-sm">
              {new Date(bet.placed_at).toLocaleDateString()}
            </div>
          </div>
          <span className={`px-3 py-1 rounded-lg text-sm font-medium ${getStatusStyle(status || '')}`}>
            {status}
          </span>
        </div>

        {!isParlay && (
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-[#8F9BB3]">Sport</span>
              <span className="text-white">{firstLeg?.sport?.name || 'N/A'}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-[#8F9BB3]">League</span>
              <span className="text-white">{firstLeg?.league?.name || 'N/A'}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-[#8F9BB3]">Event</span>
              <span className="text-white">{firstLeg?.event_name || 'N/A'}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-[#8F9BB3]">Selection</span>
              <span className="text-white">{firstLeg?.selection || 'N/A'}</span>
            </div>
          </div>
        )}

        {isParlay && isExpanded && (
          <div className="space-y-4">
            {bet.legs.map((leg, index) => (
              <div key={index} className="pl-4 border-l-2 border-[#1A1A23] space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-[#8F9BB3]">Sport</span>
                  <span className="text-white">{leg.sport?.name || 'N/A'}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-[#8F9BB3]">League</span>
                  <span className="text-white">{leg.league?.name || 'N/A'}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-[#8F9BB3]">Event</span>
                  <span className="text-white">{leg.event_name || 'N/A'}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-[#8F9BB3]">Selection</span>
                  <span className="text-white">{leg.selection || 'N/A'}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-[#8F9BB3]">Status</span>
                  <span className={`px-2 py-0.5 rounded ${getStatusStyle(leg.bet_status?.name || '')}`}>
                    {leg.bet_status?.name || 'N/A'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-between pt-2 border-t border-[#1A1A23]">
          <div className="text-sm">
            <div className="text-[#8F9BB3]">Stake</div>
            <div className="text-white font-medium">{formatCurrency(bet.stake)}</div>
          </div>
          <div className="text-sm">
            <div className="text-[#8F9BB3]">Odds</div>
            <div className="text-white font-medium">{formatOdds(bet.odds)}</div>
          </div>
          <div className="text-sm">
            <div className="text-[#8F9BB3]">P/L</div>
            <div className="text-white font-medium">{calculatePL(bet)}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopTable = () => (
    <div className="hidden md:block overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b border-[rgba(255,255,255,0.05)]">
            <th className="text-left p-4 text-[#8F9BB3]">Type</th>
            <th className="text-left p-4 text-[#8F9BB3]">Date</th>
            <th className="text-left p-4 text-[#8F9BB3]">Sport</th>
            <th className="text-left p-4 text-[#8F9BB3]">League</th>
            <th className="text-left p-4 text-[#8F9BB3]">Event</th>
            <th className="text-left p-4 text-[#8F9BB3]">Selection</th>
            <th className="text-right p-4 text-[#8F9BB3]">Stake</th>
            <th className="text-right p-4 text-[#8F9BB3]">Odds</th>
            <th className="text-right p-4 text-[#8F9BB3]">Payout</th>
            <th className="text-right p-4 text-[#8F9BB3]">P/L</th>
            <th className="text-center p-4 text-[#8F9BB3]">Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-[rgba(255,255,255,0.05)]">
          {filteredBets.map((bet) => {
            const isParlay = bet.legs.length > 1;
            return isParlay ? renderParlayRow(bet) : renderSingleRow(bet);
          })}
        </tbody>
      </table>
    </div>
  );
  

  const renderSingleRow = (bet: Bet) => {
    const leg = bet.legs[0];
    const odds = leg?.odds || bet.odds || '-';
    
    return (
      <tr key={bet.id} className="hover:bg-[#13131A] transition-all duration-200">
        <td className="p-4 text-[#8F9BB3]">
          <div className="flex flex-col">
            <div>Single</div>
            <div className="text-sm opacity-75">{leg?.leg_type?.name || 'N/A'}</div>
          </div>
        </td>
        <td className="p-4 text-[#8F9BB3]">
          {new Date(bet.placed_at).toLocaleDateString()}
        </td>
        <td className="p-4 text-[#8F9BB3]">{leg?.sport?.name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.league?.name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.event_name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.selection || 'N/A'}</td>
        <td className="p-4 text-right text-[#8F9BB3]">
          {formatCurrency(bet.stake)}
        </td>
        <td className="p-4 text-right text-[#8F9BB3]">{formatOdds(odds)}</td>
        <td className="p-4 text-right text-[#8F9BB3]">
          {calculatePayout(bet)}
        </td>
        <td className="p-4 text-right text-[#8F9BB3]">
          {calculatePL(bet)}
        </td>
        <td className="p-4 text-center">
          <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(leg?.bet_status?.name || '')}`}>
            {leg?.bet_status?.name || 'N/A'}
          </span>
        </td>
      </tr>
    );
  };

  const renderParlayRow = (bet: Bet) => {
    const isExpanded = expandedParlays.has(bet.id);
    const parlayStatus = getParlayStatus(bet);
    
    return (
      <>
        <tr key={bet.id} className="hover:bg-[#13131A] transition-all duration-200">
          <td className="p-4 text-[#8F9BB3]">
            <div className="flex items-center gap-2">
              <span>Parlay ({bet.legs.length} Legs)</span>
              <button
                onClick={() => toggleParlay(bet.id)}
                className="text-cyan-500 hover:text-[#8F9BB3] transition-colors"
              >
                {isExpanded ? '▼' : '▶'}
              </button>
            </div>
          </td>
          <td className="p-4 text-[#8F9BB3]">
            {new Date(bet.placed_at).toLocaleDateString()}
          </td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-right text-[#8F9BB3]">
            {formatCurrency(bet.stake)}
          </td>
          <td className="p-4 text-right text-[#8F9BB3]">{formatOdds(bet.odds)}</td>
          <td className="p-4 text-right text-[#8F9BB3]">
            {calculatePayout(bet)}
          </td>
          <td className="p-4 text-right text-[#8F9BB3]">
            {calculatePL(bet)}
          </td>
          <td className="p-4 text-center">
            <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(parlayStatus)}`}>
              {parlayStatus}
            </span>
          </td>
        </tr>
        
        {isExpanded && bet.legs.map((leg, index) => (
          <tr key={`${bet.id}-${index}`} className="bg-[#13131A]">
            <td className="p-4 text-[#8F9BB3] pl-8">
              <div className="text-sm opacity-75">{leg.leg_type?.name || 'N/A'}</div>
            </td>
            <td className="p-4 text-[#8F9BB3]"></td>
            <td className="p-4 text-[#8F9BB3]">{leg.sport?.name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.league?.name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.event_name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.selection || 'N/A'}</td>
            <td className="p-4 text-right text-[#8F9BB3]"></td>
            <td className="p-4 text-right text-[#8F9BB3]">{formatOdds(leg.odds)}</td>
            <td className="p-4 text-right text-[#8F9BB3]"></td>
            <td className="p-4 text-center">
              <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(leg.bet_status?.name || '')}`}>
                {leg.bet_status?.name || 'N/A'}
              </span>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] overflow-hidden">
      <div className="px-4 md:px-6 py-4 border-b border-[rgba(255,255,255,0.05)] flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <h2 className="bg-gradient-to-r from-[#FFD426] to-[#00F6FF] bg-clip-text text-transparent font-semibold">
          BET HISTORY
          {selectedBookmaker && ` - ${selectedBookmaker}`}
        </h2>
        
        {/* Mobile Filter Toggle */}
        <button 
          className="md:hidden flex items-center gap-2 text-[#8F9BB3] hover:text-white transition-colors"
          onClick={() => setShowMobileFilters(!showMobileFilters)}
        >
          <Menu size={20} />
          <span>Filters</span>
        </button>

        {/* Desktop Filters */}
        <div className="hidden md:flex gap-2">
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:bg-[#2A2A35] transition-colors"
            value={bookmakerFilter}
            onChange={(e) => setBookmakerFilter(e.target.value)}
          >
            <option value="all" className="bg-[#1A1A23] text-white">All Bookmakers</option>
            {bookmakers.map(bookmaker => (
              <option key={bookmaker} value={bookmaker} className="bg-[#1A1A23] text-white">
                {bookmaker}
              </option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={sportFilter}
            onChange={(e) => setSportFilter(e.target.value)}
          >
            <option value="all">All Sports</option>
            {sports.map(sport => (
              <option key={sport} value={sport}>{sport}</option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={leagueFilter}
            onChange={(e) => setLeagueFilter(e.target.value)}
          >
            <option value="all">All Leagues</option>
            {leagues.map(league => (
              <option key={league} value={league}>{league}</option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Statuses</option>
            {statuses.map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Mobile Filters */}
      {renderMobileFilters()}

      {/* Desktop Table / Mobile Cards */}
      <div className="md:p-4">
        <div className="md:hidden space-y-4">
          {filteredBets.map(bet => renderMobileBetCard(bet))}
        </div>
        {renderDesktopTable()}
      </div>
    </div>
  );
};

export default BetsOverview;
