import React, { useEffect, useState } from 'react';
import { getTeamAbbreviation } from '../../../utils/nhlUtils';

interface TeamStanding {
  Team: string;
  GW: string;
  W: string;
  L: string;
  OTL: string;
  PTN: string;
  STRK: string;
}

interface StandingsData {
  eastern: TeamStanding[];
  western: TeamStanding[];
}

const NHLStandings: React.FC = () => {
  const [standings, setStandings] = useState<StandingsData>({
    eastern: [],
    western: []
  });
  const [activeTab, setActiveTab] = useState('Eastern Conference');

  useEffect(() => {
    const processTeamData = (row: Element) => {
      const cells = Array.from(row.getElementsByTagName('td'));
      const teamCell = cells[0];
      const teamNameElement = teamCell?.querySelector('.bsp_row_teamname');
      return {
        Team: teamNameElement?.textContent?.trim() || '',
        GW: cells[2]?.textContent?.trim() || '',
        W: cells[3]?.textContent?.trim() || '',
        L: cells[4]?.textContent?.trim() || '',
        OTL: cells[5]?.textContent?.trim() || '',
        PTN: cells[6]?.textContent?.trim() || '',
        STRK: cells[7]?.textContent?.trim() || ''
      };
    };

    const fetchStandings = async () => {
      try {
        const response = await fetch('https://script.google.com/macros/s/AKfycbxlk7fTnjEnSxPaj9Cuj1bWv9f2B7Ioo0qldmQLXem62n8pq0-eWELgDxrGY02RAXfxeA/exec');
        const text = await response.text();
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(text, 'text/html');
        const tables = htmlDoc.getElementsByTagName('table');

        const easternData = Array.from(tables[0]?.querySelectorAll('.bsp_row_item') || [])
          .map(processTeamData);
        const westernData = Array.from(tables[1]?.querySelectorAll('.bsp_row_item') || [])
          .map(processTeamData);

        setStandings({
          eastern: easternData,
          western: westernData
        });
      } catch (error) {
        console.error('Error fetching standings:', error);
      }
    };

    fetchStandings();
    const interval = setInterval(fetchStandings, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getStreakColor = (streak: string) => {
    if (streak.startsWith('W')) return 'text-[#00FFB2]';
    if (streak.startsWith('L')) return 'text-[#FF3D71]';
    return 'text-white';
  };

  const currentData = activeTab === 'Eastern Conference' ? standings.eastern : standings.western;

  return (
    <div className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] overflow-hidden">
      <div className="px-3 md:px-6 py-3 md:py-4 border-b border-[rgba(255,255,255,0.05)]">
        <h2 className="bg-gradient-to-r from-[#FFD426] to-[#00F6FF] bg-clip-text text-transparent font-semibold text-sm md:text-base">
          STANDINGS - {new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
        </h2>
      </div>

      <div className="p-2 md:p-4">
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 mb-4 md:mb-6">
          <button
            onClick={() => setActiveTab('Eastern Conference')}
            className={`px-3 md:px-4 py-2 rounded-lg text-xs md:text-sm font-medium transition-all w-full sm:w-auto ${
              activeTab === 'Eastern Conference'
                ? 'bg-[#4263EB] text-white'
                : 'bg-[#13131A] text-[#8F9BB3] hover:text-white'
            }`}
          >
            Eastern Conference
          </button>
          <button
            onClick={() => setActiveTab('Western Conference')}
            className={`px-3 md:px-4 py-2 rounded-lg text-xs md:text-sm font-medium transition-all w-full sm:w-auto ${
              activeTab === 'Western Conference'
                ? 'bg-[#4263EB] text-white'
                : 'bg-[#13131A] text-[#8F9BB3] hover:text-white'
            }`}
          >
            Western Conference
          </button>
        </div>

        <div className="overflow-x-auto -mx-2 md:mx-0">
          <table className="w-full min-w-[640px]">
            <thead>
              <tr className="border-b border-[rgba(255,255,255,0.05)]">
                <th className="text-left p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">Team</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">GP</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">W</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">L</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">OTL</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">PTS</th>
                <th className="text-center p-2 md:p-4 text-[#8F9BB3] font-medium text-xs md:text-sm">STRK</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[rgba(255,255,255,0.05)]">
              {currentData.map((team) => (
                <tr key={team.Team} className="hover:bg-[#13131A] transition-all duration-200">
                  <td className="p-2 md:p-4">
                    <div className="flex items-center gap-2 md:gap-3">
                      <div className="w-6 h-6 md:w-8 md:h-8 rounded-full bg-[rgba(255,255,255,0.03)] p-1.5 flex items-center justify-center">
                        <img
                          src={`https://assets.nhle.com/logos/nhl/svg/${getTeamAbbreviation(team.Team)}_light.svg`}
                          alt={team.Team}
                          className="w-full h-full object-contain"
                          onError={(e) => {
                            const img = e.target as HTMLImageElement;
                            img.src = '/assets/img/nhl-logos/default.png';
                          }}
                        />
                      </div>
                      <span className="text-white font-medium text-xs md:text-sm">{team.Team}</span>
                    </div>
                  </td>
                  <td className="p-2 md:p-4 text-center text-[#8F9BB3] text-xs md:text-sm">{team.GW}</td>
                  <td className="p-2 md:p-4 text-center text-white text-xs md:text-sm">{team.W}</td>
                  <td className="p-2 md:p-4 text-center text-[#8F9BB3] text-xs md:text-sm">{team.L}</td>
                  <td className="p-2 md:p-4 text-center text-[#8F9BB3] text-xs md:text-sm">{team.OTL}</td>
                  <td className="p-2 md:p-4 text-center text-white text-xs md:text-sm">{team.PTN}</td>
                  <td className={`p-2 md:p-4 text-center text-xs md:text-sm ${getStreakColor(team.STRK)}`}>{team.STRK}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NHLStandings;